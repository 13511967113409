import React from 'react';
import { Link } from 'gatsby';
import { Header, List } from 'semantic-ui-react';
import Layout from '../components/layout';
import Helmet from '../components/Helmet';
import * as DefaultTemplate from '../components/ContentPages/DefaultTemplate';

const PrivacyPolicyPage = props => (
    <Layout
        showDisclosures={false}
    >
        <Helmet
            title="Privacy"
            description="View the Privacy Policy governing DebtConsolidation.com."
            robots="noindex, follow"
        />
        <DefaultTemplate.Wrapper>
            <DefaultTemplate.Body>
                <Header as="h1" size="large">
                    Privacy Policy
                    <Header.Subheader>
                        Effective: 04/20/2022
                    </Header.Subheader>
                </Header>
                <p>
                    The information in this privacy policy applies only to this website
                    {' '}
                    <Link to="/">www.Debtconsolidation.com</Link>
                    {' '}
                    which is owned and operated by Consolidation Resource, LLC (&ldquo;Debtconsolidation.com&rdquo;, &ldquo;We&ldquo;, &ldquo;Our&ldquo; or &ldquo;Us&ldquo;).
                </p>
                <p>
                    Debtconsolidation.com respects your right to privacy. Your privacy is important to us. We understand that maintaining the confidentiality of your financial situation is important to you. We want you to understand what information we gather about you, how we use it, and the safeguards we have in place to protect it. We also want you to understand that in connection with a request for our assistance, you may ask us to intervene with your creditors on your behalf, and that the sharing of certain non-public information with them is a necessary part of the credit counseling process.
                </p>
                <p>
                    The following discloses the information gathering and dissemination practices for this web site.
                </p>
                <Header as="h2" size="medium" className="no-margin">
                    Our Principles for Privacy Protection
                </Header>
                <p>
                    Our site&rsquo;s registration and Online forms require users to give us contact information (like their name, email, or postal address). Due to the nature of this web site, financial information is also requested on a voluntary basis from the visitor. We do not share your financial information except in accordance with applicable law and with affiliated parties for the purpose of providing the services contemplated by your use of the Website. By using and/or continuing to use the Website, and by providing information on the Website, you agree that We may use the information you provide in accordance with applicable law and this Privacy Policy. With your express permission, we may rent, sell or share your personal information to affiliated third parties including, without limitation, advertisers, and marketers We maintain security practices and procedures designed to ensure the security, integrity, and confidentiality of your personal financial information. We educate our employees about safeguarding client information and preventing unauthorized access, disclosure or use, while limiting employee access to client information to those who need it to assist you.
                </p>
                <Header as="h2" size="medium" className="no-margin">
                    What Information Do We Collect?
                </Header>
                <p>
                    We collect your personal information, including without limitation, all information you provide, including personal information such as name, date of birth, telephone number (home, work and mobile), street address, e-mail address and social security number, including but not limited to, any other information which you provide to us through other channels. We may also collect and use your personal, demographic, and profile data to improve the Website, conduct statistical analysis, and for marketing, promotional, editorial, or feedback purposes. By agreeing to this policy or by using this Website, you authorize us to use your information to obtain other information about you from third-parties, such as your Social Security Number, where such information was incompletely or improperly filled out on our submission form. Such third-parties include, but are not limited to. Consumer reporting agencies, consumer credit bureaus and other outside parties as permitted or required by applicable laws. We may collect non-public personal information about you from the following sources:
                </p>
                <List as="ul">
                    <List.Item as="li">
                        Information we receive from you on applications or provided by you through other forms, whether submitted through the Internet or manually, which may include, without limitation, information received from you through telephone calls, and e-mail communications. This information may include your name, address, telephone (home, work, cellular) and social security number, as well as your creditors&rsquo; names and addresses, the amounts you owe your creditors and their account numbers, credit card account information, and your living expenses.
                    </List.Item>
                    <List.Item as="li">
                        Information you provide to Us pertaining to certain banking information via the Internet, on the phone or in person, in order to process ACH payments.
                    </List.Item>
                    <List.Item as="li">
                        Information about your transactions with Us.
                    </List.Item>
                    <List.Item as="li">
                        Information we receive from your creditors to help us with customer authentication and credit-related decisions.
                    </List.Item>
                </List>
                <Header as="h2" size="medium" className="no-margin">
                    Cookies and Other Tracking Technologies
                </Header>
                <p>
                    Technologies such as: cookies, beacons, tags and scripts are used by Debtconsolidation.com and our marketing partners, affiliates, or analytics or service providers. These technologies are used in analyzing trends, administering the website, tracking users&rsquo; movements around the website and to gather demographic information about our user base as a whole. We may receive reports based on the use of these technologies by these companies on an individual as well as aggregated basis.
                </p>
                <p>
                    We use cookies for to remember users&rsquo; settings (e.g. language preference) and for authentication. Users can control the use of cookies at the individual browser level. If you reject cookies, you may still use our website, but your ability to use some features or areas of our site may be limited.
                </p>
                <Header as="h2" size="medium" className="no-margin">
                    Log Files
                </Header>
                <p>
                    As is true of most web sites, we gather certain information automatically and store it in log files. This information may include internet protocol (IP) addresses, browser type, internet service provider (ISP), referring/exit pages, operating system, date/time stamp, and/or clickstream data. We do not link this automatically collected data to other information we collect about you.
                </p>
                <Header as="h2" size="medium" className="no-margin">
                    Local Storage Object (HTML 5)
                </Header>
                <p>
                    Third parties with whom we partner to provide certain features on our website or to display advertising based upon your Web browsing activity use LSOs such as HTML 5. Various browsers may offer their own management tools for removing HTML5 LSOs.
                </p>
                <Header as="h2" size="medium" className="no-margin">
                    Online Behavioral Advertising
                </Header>
                <p>
                    We partner with a third party ad network to either display advertising on our Website or to manage our advertising on other sites. Our ad network partner uses cookies and Web beacons to collect non-personal information about your activities on this and other Web sites to provide you targeted advertising based upon your interests. 
                </p>
                <span id="information-use" />
                <Header as="h2" size="medium" className="no-margin">
                    What Information Do We Disclose?
                </Header>
                <p>
                    We use the information we collect to enhance our services and enhance your experience at our Website as well as to help us to offer content we determine may be of interest to you. We may disclose non-public personal information about you in the course of providing credit counseling services on your behalf. If you choose to provide personal information, it will be used for and/or shared with trusted third parties such as debt consolidators and/or other financial service providers, credit bureaus, and for marketing products and services that you might find of interest. We reserve the right to share, rent, sell or otherwise disclose your information with third parties in accordance with applicable law. These third party businesses may include, but are not limited to: providers of direct marketing services and applications, including lookup and reference, data enhancement, suppression and validation, e-mail marketers, and telemarketers. If you do not want us to share your personal information with these companies, contact us at
                    {' '}
                    <a href="mailto:customerservice@debtconsolidation.com">customerservice@debtconsolidation.com</a>
                    .
                </p>
                <p>
                    By using this Website, you agree that you may be contacted in any manner contemplated in this section even if your number is found in the national Do-Not-Call registry or in any other do-not-call or similar registry. By submitting your information to the Website, you further agree to receive mobile marketing, that you may be contacted at the telephone number or mobile telephone number provided, and to receive SMS messages, including without limitation, marketing messages, from us or an affiliated third party regardless of whether or not your number is found on the national Do-Not-Call Registry or similar registry. You agree that you are at least 18 years of age and the authorized proprietor of any such mobile phone. Standard data and messaging rates will apply.
                </p>
                <Header as="h2" size="medium" className="no-margin">
                    To Whom Do We Disclose Information?
                </Header>
                <p>
                    We may also employ other companies and individuals to perform certain functions on our behalf. Examples may include sending direct mail and e-mail, removing duplicate information from customer lists, analyzing data, and providing marketing analysis. These agents have access to our users’ personal information as needed only to perform their functions for Us, but we do not permit them to use Our users’ personal information except as expressly permitted by Us and in accordance with applicable laws and regulations.
                </p>
                <p>
                    We may disclose non-public personal information about you to your financial service provider creditors such as banks, saving and loans, credit unions, mortgage bankers, finance companies, and credit card issuers. For the purpose of fulfilling our mission and providing you with certain services and to process your requests and payments, we may disclose your personal information to one or more service providers or other third parties who are assisting Us in performing such services. Without limiting the foregoing, these third parties may provide Us with data management, payment processing, and similar services. Additionally, in the event that you fail to complete a program you enroll in, information may be referred to a servicing agent to contact you to provide additional financial services that you may want to consider.
                </p>
                <p>
                    We may also disclose your personal information as required by law, such as to comply with a subpoena, or similar legal process, and when we believe in good faith that disclosure is necessary to protect our rights, protect your safety or the safety of others, investigate fraud, or respond to a government request.
                </p>
                <p>
                    If Debtconsolidation.com is involved in a merger, acquisition, or sale of all or a portion of its assets, you will be notified via email and/or a prominent notice on our Website of any change in ownership or uses of your personal information, as well as any choices you may have regarding your personal information.
                </p>
                <Header as="h2" size="medium" className="no-margin">
                    How Do We Protect Information?
                </Header>
                <p>
                    Only those employees or servicing agents obligated to maintain the confidentiality of information who might need to know non-public personal information in order to provide assistance to you have access to such information. We follow generally accepted standards to protect the personal information submitted to us, both during transmission and once we receive it. All information collected is stored in a technically and physically secure environment. When you enter sensitive information (such as credit card numbers and SSN) on our website, we encrypt the transmission of that information using secure socket layer technology (SSL). However, no data transmission over the Internet can be guaranteed to be 100% secure. Therefore, we cannot warrant the security of any information that you transmit to us, and you do so at your own risk. Our employees are required to ensure the security and privacy of all user information. If you have any questions about security on our Web site, you can contact us at
                    {' '}
                    <a href="mailto:customerservice@debtconsolidation.com">customerservice@debtconsolidation.com</a>
                    .
                </p>
                <Header as="h2" size="medium" className="no-margin">
                    Short Message Service
                </Header>
                <p>
                    Debtconsolidation.com may make available a service through which you can receive financial advice on your wireless device via short message service (“SMS Service”). Data obtained from you in connection with this SMS service may include your name, address, cell phone number, your provider&rsquo;s name, the date, time, and any content provided by you or otherwise contained in your messages. You represent that you are 18 years of age and the owner or authorized user of the wireless device on which messages will be received, and that you are authorized to approve the applicable charges. In addition to any fee of which you are notified, your provider&rsquo;s standard message and data rates may apply to our confirmation and all subsequent SMS correspondence. All charges are billed by and payable to your mobile service provider. Consolidated Credit Counseling Services will not be liable for any delays in the receipt of any SMS messages, as delivery is subject to effective transmission from your network operator. SMS message services are provided on an AS IS basis. Consolidated Credit Counseling Services may also obtain the date, time and content of your messages in the course of your use of the SMS Service. We will use the information we obtain in connection with our SMS Service in accordance with this Privacy Policy. If fees are charged to your wireless account invoice, we may provide your carrier with your applicable information in connection therewith. Your wireless carrier and other service providers may also collect data about your wireless device usage, and their practices are governed by their own policies.
                </p>
                <p>
                    You acknowledge and agree that the SMS Service is provided via wireless systems which use radios (and other means) to transmit communications over complex networks. You must have a two way text-enables phone with compatible carrier and plan. Compatible major carriers include Alltel Wireless, AT&T, Boost Mobile, Nextel, Sprint, T-Mobile ®, Verizon Wireless and Virgin Mobile USA. We do not guarantee that your use of the SMS Service will be private or secure, and we are not liable to you for any lack of privacy or security you may experience. You are fully responsible for taking precautions and providing security measures best suited for your situation and intended use of the SMS Service. We may also access the content of your account and wireless account with your carrier for the purpose of identifying and resolving technical problems and service-related complaints.
                </p>
                <p>
                    Should you hereafter choose to opt-out of receiving SMS Messages from the individual sender, you may opt-out by texting &ldquo;STOP&rdquo; or &ldquo;OPT-OUT&rdquo; as specifically set forth in the communication. Notwithstanding that your mobile telephone number may be listed on state and/or federal do-not-call registries, we retain the right to contact you via SMS Messages. In addition, by registering and/or using the Website, you agree that such act constitutes an inquiry and/or application for purposes of the Amended Telemarketing Sales Rule (16 CFR 310 et seq.), as amended from time to time. As such, notwithstanding that your mobile telephone number may be listed on state and/or federal Do-Not-Call registries, we retain the right to contact you via text-message based marketing in accordance with applicable state and federal law. Further, you agree that we reserve the right to share, sell, rent, lease and/or otherwise distribute your mobile telephone and other mobile data with/to any third-party for any and all non-marketing uses permitted by this Privacy Policy and applicable law.”
                </p>
                <Header as="h2" size="medium" className="no-margin">
                    Data Quality/Access
                </Header>
                <p>
                    This site gives users the following options for changing, deleting and modifying information previously provided. You can go to
                    {' '}
                    <a href="mailto:customerservice@debtconsolidation.com">customerservice@debtconsolidation.com</a>
                    {' '}
                    to submit your request, or contact Us using the information below. We will respond to your request to access within 30 days.
                </p>
                <p>
                    We will retain your information for as long as your account is active or as needed to provide you services. We will retain and use your information as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements.
                </p>
                <span id="opt-out-rights" className="anchor" />
                <Header as="h2" size="medium" className="no-margin">
                Your Opt-Out Rights
                </Header>
                <p>
You may opt-out of receiving communications from us and or our third-party associates by not submitting your information. During registration and/or when you submit personally identifiable information to us at the website, you have opted-in to request that we share your personal information with third parties to receive marketing communications for the specific purposes of receiving offers that you have applied for on our websites. When contacted by any of these companies or third parties, you should notify them directly of your choices regarding their use and sharing of your information and to opt-out of receiving additional offers from them or any other third party with whom we have shared your information (see &ldquo;
    {' '}
                    <a href="#information-use">What Information Do We Disclose?</a>
&rdquo; above). As noted, we use personal information to provide promotional offers through direct marketing, including without limitation e-mail, telephone, or SMS Messages Service to our website users. We may maintain separate e-mail lists for different purposes. If e-mail recipients wish to end their e-mail subscription from a particular list, they need to follow the instructions at the end of each e-mail message to unsubscribe from the particular list. To opt-out from receiving any additional email communications regarding the website, please send your request in writing via email to
{' '}
                    <a href="mailto:customerservice@debtconsolidation.com">customerservice@debtconsolidation.com</a>
.
                </p>

                <span id="do-no-sell-personal-info" className="anchor" />
                <div className="content-highlight">
                    <p>
                        <strong>Do not sell my personal information</strong>
                        <br />
                    You may also opt-out of having your personally identifiable information sold to third-parties. During registration and/or when you submit personally identifiable information to us at the website, you have opted-in to request that we share your personal information with third parties, including Loan Providers and/or other financial service providers for the specific purposes of receiving offers that you have applied for on our websites. When contacted by any of these companies or third parties, you should notify them directly of your choices regarding their use and sharing of your information.
                        {' '}
                        <strong><em>Please note that if you opt-out of having your personally identifiable information sold to third-parties, including Loan Providers and/or other financial service providers, you will not be able to receive the services sought for which you originally visited this website.</em></strong>
                    </p>
                    <p>
To opt-out from having your personally identifiable information sold to third-parties, including Loan Providers and/or other financial service providers, please send your request in writing via email to
                        {' '}
                        <a href="mailto:customerservice@debtconsolidation.com">customerservice@debtconsolidation.com</a>
                        {' '}
or via postal mail to Consolidation Resource, LLC 13014 N. Dale Mabry Hwy. Ste. 211 Tampa, FL 33618.
                    </p>
                    <p>In addition, please note that if you subsequently sign up at one of our other websites for other product or service offerings, you will need to again address any information sharing preferences that you had previously established through this website.</p>
                </div>
                <p>
                    We may also send you service related email announcements on rare occasions when it is necessary to do so. For instance, if our service is temporarily suspended for maintenance, we might send you an email. You do no have an option to opt out of these emails, which are not promotional in nature.
                </p>

                <Header as="h2" size="medium" className="no-margin">
                    Access to Personally Identifiable Information
                </Header>

                <p>
We will retain your information for as long as needed to provide you services. If you would like to review, update or remove your personally identifiable information, you may submit your request by contacting us at
                    {' '}
                    <a href="mailto:customerservice@debtconsolidation.com">customerservice@debtconsolidation.com</a>
, sending your request via postal mail to Consolidation Resource, LLC 13014 N. Dale Mabry Hwy. Ste. 211 Tampa, FL 33618. In order for us to process your request, we will need to be able to verify your identity to the extent necessary to ensure that you are the person you represent yourself to be. As appropriate under the circumstances of the request, you may be required to comply with one of the following identification verification methods:
                    {' '}
                </p>

                <ol className="list">
                    <li>
Certification of Identity: Provision of a completed Certification of Identity. A template certification of identity is available
                        {' '}
                        <a href="/assets/pdf/consumer-intitiated-data-inquiry.pdf">here</a>
.
                    </li>
                    <li>
Official Photo Identification: Provision of an official photo identification, which includes, but is not limited to, the following:
                        <ul className="list">
                            <li>A valid driver&rsquo;s license.</li>
                            <li>An unexpired passport.</li>
                            <li>An unexpired federal government-issued employee identification card.</li>
                        </ul>
                    </li>
                </ol>

                <p>You will be provided a reasonable amount of time to provide sufficient identity verification. Any request may be denied for lack of sufficient identity verification.</p>
                <Header as="h2" size="medium" className="no-margin">
                    We Do Not Intentionally Collect Data from Children
                </Header>
                <p>
                    Individuals under the age of eighteen (18) are not permitted to obtain products and/or services from debtconsolidation.com. We do not intentionally target our services to children under the age of eighteen (18) and will not knowingly collect a minor’s information. We encourage parents and guardians to spend time online with their children and to participate and monitor the interactive activities of their children.
                </p>
                <Header as="h2" size="medium" className="no-margin">
                    External Links
                </Header>
                <p>
                    This site contains links to other sites. Debtconsolidation.com is not responsible for the privacy practices or the content of such websites. The contents of such websites are unknown and in no event, should you assume that debtconsolidation.com condones, approves of, or recommends the content of any website that is linked from the debtconsolidation.com pages or any other pages of this Website. Complaints about the content of any linked site from the Website pages should be directed to the owner of that site and not debtconsolidation.com. We do not endorse and are not responsible for the accuracy of the privacy policies or practices of any such third party website or service provider that may advertiser or otherwise link on the Website. The entities that advertise and/or place banner ads at the Website are independent third parties and are not affiliated with Us.
                </p>
                <Header as="h2" size="medium" className="no-margin">
                    Testimonials
                </Header>
                <p>
                    We display personal testimonials of satisfied customers on our website in addition to other endorsements. With your consent we may post your testimonial along with your name. If you wish to update or delete your testimonial, you can contact us at
                    {' '}
                    <a href="mailto:customerservice@debtconsolidation.com">customerservice@debtconsolidation.com</a>
                    .
                </p>
                <Header as="h2" size="medium" className="no-margin">
                    Social Media Features
                </Header>
                <p>
                    Our Web site includes Social Media Features, such as the Facebook Like button and Widgets, such as the Share this button or interactive mini-programs that run on our site. These Features may collect your IP address, which page you are visiting on our site, and may set a cookie to enable the Feature to function properly. Social Media Features and Widgets are either hosted by a third party or hosted directly on our Site. Your interactions with these Features are governed by the privacy policy of the company providing it.
                </p>
                <Header as="h2" size="medium" className="no-margin">
                    Questions or Corrections?
                </Header>
                <p>
                    If you have any question about our privacy policy and practices please contact us at
                    {' '}
                    <a href="mailto:customerservice@debtconsolidation.com">customerservice@debtconsolidation.com</a>
                    {' '}
                    or through the postal address below. If you want to correct any personal information in our files, please submit a written request to us. Thank you for choosing debtconsolidation.com to assist you in restoring your financial wellbeing.
                </p>
                <Header as="h2" size="medium" className="no-margin">
                    Revised Privacy Policies
                </Header>
                <p>
                    Please note that we reserve the right to change or modify our privacy practices that are described above from time to time. Any such change shall be effective immediately upon posting of the revised Privacy Policy on the Website. The revised Privacy Policy shall be effective for information we already have about you, as well as any information we receive in the future. If we make any material changes we will notify you by email (sent to the e-mail address specified in your account) or by means of a notice on this Website. You should refer to this Privacy Policy on an ongoing basis so that you are aware of our current privacy practices.
                </p>
                <Header as="h2" size="medium" className="no-margin">
                    Our Contact Information
                </Header>
                <p>
                    Consolidation Resource, LLC
                    13014 N. Dale Mabry Hwy. Ste. 211
                    Tampa, FL 33618
                </p>
                <p>
                    Email:
                    {' '}
                    <a href="mailto:customerservice@debtconsolidation.com">customerservice@debtconsolidation.com</a>
                </p>
            </DefaultTemplate.Body>
        </DefaultTemplate.Wrapper>
    </Layout>
);

export default PrivacyPolicyPage;
